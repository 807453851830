<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

export default {
  page: {
    title: "Détails signals",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Détails Alertes",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Détails signals",
          active: true,
        },
      ],
      cleanData: [],
      ordersData: [],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      DetailAlertesData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "itinéraire", label: "trajet" },
        { key: "profil", sortable: true, label: "Profil" },
        { key: "contact", label: "Personne à contacter" },
        { key: "signaledBy", sortable: true, label: "Signalé par" },
        { key: "date", sortable: true, label: "Date" },
        { key: "propos", label: "Propos" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataSignal: [],
      recherche: ""
    };
  },
  async mounted() {
    const DetailAlertes = await apiRequest(
      "GET",
      "admin/signals",
      undefined,
      false
    );

    if (DetailAlertes && DetailAlertes.data) {


      const DetailAlerteTable = DetailAlertes.data.signalFound.map((element) => {
        if (

          element.signaler &&
          element.user &&
          element.trajet &&

          element.signaler.personne

        ) {
          this.cleanData.push(element)
          this.totalValue = DetailAlertes.data.total

          return {
            itinéraire:
              element.trajet.lieuDepart + " ↦ " + element.trajet.lieuArrivee,
            profilId: element.signaler.personne.id,
            profil:
              element.signaler.personne.lastname +
              " " +
              element.signaler.personne.firstname,
            signaledById: element.user.personne.id,
            signaledBy:
              element.user.personne.lastname +
              " " +
              element.user.personne.firstname,

            contact: element.signaler.personne.indicatifTel + "" + element.signaler.personne.tel,

            date: new Date(element.datePublication).toLocaleString("fr-FR"),
            propos: element.contenu,

          };
        }
      });



      this.DetailAlertesData = DetailAlerteTable;


      this.totalRows = this.DetailAlertesData.length;
    }
  },

  methods: {
    // eslint-disable-next-line no-unused-vars

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },
    
    formSubmit() {
      this.submitted = true;

      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;

      this.formSubmit();
      this.$v.$touch();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    async actif(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "admin/signals?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {

        const DetailAlerteTable = listeWallet.data.map((element) => {
          if (

            element.signaler &&
            element.user &&
            element.trajet &&
            

            element.signaler.personne

          ) {
            this.cleanData.push(element)


            return {
              itinéraire:
                element.trajet.lieuDepart + " ↦ " + element.trajet.lieuArrivee,
              profil:
                element.signaler.personne.lastname +
                " " +
                element.signaler.personne.firstname,
              signaledBy:
                element.user.personne.lastname +
                " " +
                element.user.personne.firstname,

              contact: element.signaler.personne.indicatifTel + "" + element.signaler.personne.tel,

              date: new Date(element.datePublication).toLocaleString("fr-FR"),
              propos: element.contenu,

            };
          }
        });

        this.newDataSignal = DetailAlerteTable;
        this.userChargeLoader = false;
        this.totalRows = DetailAlerteTable.length;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/signals-recherche?mots=" + this.recherche,
        undefined,


        false
      );
      if (dataSearch && dataSearch.data) {
        const DetailAlerteTable = dataSearch.data.map((element) => {
          if (

            element.signaler &&
            element.user &&
            element.trajet &&

            element.signaler.personne

          ) {
            this.cleanData.push(element)


            return {
              itinéraire:
                element.trajet.lieuDepart + " ↦ " + element.trajet.lieuArrivee,
              profil:
                element.signaler.personne.lastname +
                " " +
                element.signaler.personne.firstname,
              signaledBy:
                element.user.personne.lastname +
                " " +
                element.user.personne.firstname,

              contact: element.signaler.personne.indicatifTel + "" + element.signaler.personne.tel,

              date: new Date(element.datePublication).toLocaleString("fr-FR"),
              propos: element.contenu,

            };
          }
        });

        this.newDataSignal = DetailAlerteTable;
        this.userChargeLoader = false;
        this.totalRows = DetailAlerteTable.length;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
     
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
     
      this.actif(this.startValue, this.endValue);
    },




  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!--     <div class="containerDot" v-if="DetailAlertesData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> 
          </div> -->
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">



            <div class="row d-flex justify-content-between  p-2">
              <div class="card-title">
                <h5>Total d'éléments signalés : {{ totalRows }}</h5>
              </div>

              <transition mode="out-in">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                        data-placement="bottom" title="Prem">
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                        data-toggle="tooltip" data-placement="bottom" title="Préc">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                        title="Suiv" :disabled="endValue >= totalValue">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                        title="Dern" :disabled="endValue >= totalValue">
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"></a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center  mt-4">
                    <div class="p-3">

                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input ">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>

                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive mt-2">
                    <b-table class="table-centered" :items="newDataSignal.length != 0 ? newDataSignal : DetailAlertesData"
                      :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn" @filtered="onFiltered">

                      <template v-slot:cell(profil)="row">

                        <div @click="goToUserDetail(row.item.profilId)">
                          <span>
                            {{ row.item.profil }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>

                      <template v-slot:cell(signaledBy)="row">

                        <div @click="goToUserDetail(row.item.signaledById)">
                          <span>
                            {{ row.item.signaledBy }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>

                      <template v-slot:cell(action)>
                        <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover @click="showEdit = true"
                          title="Editer">
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover @click="showdisable = true"
                          title="Désactiver">
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a href="javascript:void(0);" class="text-danger" @click="showDelete = true" v-b-tooltip.hover
                          title="supprimer">
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                      <transition mode="out-in">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                data-toggle="tooltip" data-placement="bottom" title="Prem">
                                <ion-icon name="play-skip-back-outline"></ion-icon>
                              </button>
                              <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                                data-toggle="tooltip" data-placement="bottom" title="Préc">
                                <ion-icon name="chevron-back-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                                title="Suiv" :disabled="endValue >= totalValue">
                                <ion-icon name="chevron-forward-outline"></ion-icon>
                              </button>
                              <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                                <ion-icon name="play-skip-forward-outline"></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}

thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;

  flex: 60%;
  top: -50%;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;

}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3aaa35;
  border-color: #3aaa35;
}



ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}


.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;

}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
